import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineStar } from "react-icons/ai";
import { MdFileUpload } from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Box,
  Flex,
  Grid,
  GridItem,
  Tag,
  TagLabel,
  Stack
} from "@chakra-ui/react";
import { useAPI, useSearchSWR } from "api";
import { useAccount, useOrgLookup } from "app/auth-container";
import { useBatchProcessing } from "app/batch-processing";
import { useLocale } from "app/locale";
import { useParseURL, usePagination, parseInteger } from "app/pagination";
import { hasPermission } from "app/permissions";
import { usePreferences } from "app/preferences";
import { useSearch } from "app/search";
import { SUPPORTED_IMAGE_TYPES } from "app/upload-cases-images-container";
import {
  Button,
  IconButton,
  Heading1,
  Spinner,
  useModal,
  AddIcon,
  EditIcon,
  SearchIcon,
  CloseIcon
} from "components/core";
import { CaseSearch, CaseSearchState } from "components/cases/case-search";
import { CaseQuery } from "components/cases/case-query";
import { PredictionModel } from "components/common/model-picker";
import { PaginatedListActionBar } from "components/common/pagination";
import { deserializeCase } from "models/case";
import { SearchOptions, SearchQueryItem } from "types";
import { OrgPicker } from "components/organizations/org-picker";
import { BatchProcessingButtons } from "components/cases/case-batch-processing-buttons";
import { CaseTable } from "components/cases/case-table";
import {
  PRIVATE_LABEL_QUERY_FIELD,
  PUBLIC_LABEL_QUERY_FIELD,
  StatusIcons,
  TwotoneStarIcon
} from "components/labelled";
import { Container } from "components/layout/container";
import { useAddImagesToAnyCase } from "components/cases/dialog-add-images-to-any-case";
import modelData from "data/ai-prediction-models.json";
import { LegalWarning } from "features/ai-legal-terms/case-warning";
import { AgreementContainer } from "features/ai-legal-terms/agreement";
import { CASE_NAVIGATION_KEY } from "./case-item/case-item-shared";

export const CaseListPage = () => {
  localStorage.removeItem(CASE_NAVIGATION_KEY);
  const api = useAPI();
  const { currentOrgId } = useAccount();
  const { getPreference } = usePreferences();

  const isFilteredByOrg = !!currentOrgId && currentOrgId !== "*"; // handle users without any org and request by super users to see all cases
  const limit = parseInteger(getPreference("casesPerPage"), {
    defaultValue: 10,
    minValue: 1
  });

  const searchOptions = useParseURL({
    query: isFilteredByOrg
      ? [{ field: "organizationId", value: currentOrgId, operator: "is" }]
      : [],
    limit,
    order: [{ field: "createdAt", direction: "DESC" }]
  });

  const { data, error, mutate } = useSearchSWR(
    "/api/cases/search",
    api.cases.find,
    {
      ...searchOptions,
      query: [
        ...searchOptions.query.map(query => ({
          ...query,
          value: query.operator === "is-like" ? `%${query.value}%` : query.value
        }))
      ]
    }
  );

  if (error) {
    return (
      <Alert status="error">
        Unable to load the list of cases {error.message}
      </Alert>
    );
  }

  if (!data) {
    return <Spinner />;
  }

  const { data: caseListData, total } = data;
  const cases = caseListData.map(deserializeCase);

  return (
    <PaginatedCaseList
      cases={cases}
      total={total}
      searchOptions={searchOptions}
      reload={mutate}
      orgId={currentOrgId}
    />
  );
};

const queryFieldsHasImage = [
  "displayName",
  "organ",
  "modelName",
  "specimenType"
];

const PaginatedCaseList = ({
  cases,
  total,
  searchOptions,
  reload,
  orgId
}: {
  cases: Medmain.Case[];
  total: number;
  searchOptions: SearchOptions;
  reload: () => void;
  orgId: string;
}) => {
  const modal = useModal();
  const locale = useLocale();
  const history = useHistory();
  const {
    currentOrgId,
    isMemberRoleOfOrg,
    isSuper,
    organizations
  } = useAccount();
  const isMemberFlg = isSuper() || isMemberRoleOfOrg(currentOrgId);
  const { checkAIAgreement } = AgreementContainer.useContainer();
  const {
    setSearchParams,
    getQueryField,
    setSortOrder,
    setQueryField
  } = useSearch(searchOptions);
  const { addFiles } = useAddImagesToAnyCase();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files, rejectedFiles) => addFiles(files, rejectedFiles, orgId),
    accept: SUPPORTED_IMAGE_TYPES
  });
  const { query, order } = searchOptions;
  const favoriteQuery = query.find(
    item => item.field === PRIVATE_LABEL_QUERY_FIELD
  );
  const statusQuery = query.find(
    item => item.field === PUBLIC_LABEL_QUERY_FIELD
  );
  const statusQueryValues = statusQuery ? (statusQuery.value as string[]) : [];
  const publicLabelleds = statusQueryValues.map(item => {
    const arr = item.split("-");
    return {
      caseId: undefined,
      name: arr[0],
      color: arr[1]
    };
  });
  const caseVisibility = searchOptions.caseVisibility || "ALL";
  const { offset } = usePagination({ searchOptions, total });
  const caseQuery = CaseQuery.fromJSON(query);
  const { getOrgById } = useOrgLookup();
  const org = getOrgById(orgId);
  const {
    checkBox,
    onToggleCheckBox,
    onCheckBoxAll,
    retrieveCheckedImages,
    setCheckBox,
    startAIAnalysis,
    updateAllOrganModel,
    isPending,
    isRejected,
    isFulfilled
  } = useBatchProcessing();
  const images = cases.flatMap(item =>
    item.images.map(image => ({
      caseNumber: item.caseNumber,
      ...image
    }))
  );
  const checkedImages = retrieveCheckedImages(images, "id");

  const canCreatePrediction =
    org?.predictionEnabled &&
    cases.some(item => hasPermission("case/request_prediction", item));

  const handleSearch = async () => {
    const searchState: CaseSearchState = await modal.dialog({
      modalProps: { size: "4xl" },
      render: close => (
        <CaseSearch
          query={caseQuery}
          visibility={caseVisibility}
          onClose={close}
          canCreatePrediction={!!org?.predictionEnabled}
        />
      )
    });

    if (!searchState && !favoriteQuery && !statusQuery) {
      return;
    }

    const queryItems = (searchState
      ? searchState.query.toJSON()
      : []) as SearchQueryItem[];
    const hasImage = [favoriteQuery, statusQuery].reduce((accum, q) => {
      if (q) {
        queryItems.push(q);
      }
      if (!accum && q) {
        return true;
      }
      return accum;
    }, searchState.hasImage as boolean | undefined);

    const nextLocation = setSearchParams({
      query: queryItems,
      caseVisibility: searchState.visibility,
      hasImage
    });
    history.push(nextLocation);
  };

  const shouldShowSearchSummary =
    query.some(
      ({ field }) =>
        field !== "organizationId" && field !== PRIVATE_LABEL_QUERY_FIELD
    ) || caseVisibility !== "ALL";

  useEffect(() => {
    if (isPending || (!isRejected && !isFulfilled)) return;
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending, isRejected, isFulfilled]);

  return (
    <Container align="center">
      <Flex mb={4} alignItems="center">
        <Box flexGrow={1}>
          <LegalWarning />
          <Heading1 m={0}>
            {locale.caseList.heading}
            {cases.length > 0 && (
              <Box
                as="span"
                fontSize="md"
                fontWeight={500}
                color="gray.500"
                ml={3}
              >
                {locale.formatNumber(offset + 1)}-
                {locale.formatNumber(Math.min(offset + cases.length, total))} of{" "}
                {locale.formatNumber(total)}
              </Box>
            )}
          </Heading1>
        </Box>
      </Flex>
      <Grid
        mb={5}
        rowGap={{ base: "15px", md: "20px" }}
        templateAreas={
          canCreatePrediction
            ? {
                base: `"search"
                "actions"
                "prediction"`,
                md: `"search search"
                "prediction actions"`
              }
            : {
                base: `"search"
                "actions"`,
                md: `"search actions"`
              }
        }
      >
        <GridItem area="search">
          {organizations.length > 0 && (
            <SearchBox
              query={caseQuery}
              onChangeQuery={() => {
                const nextLocation = setSearchParams({
                  query: caseQuery.toJSON()
                });
                history.push(nextLocation);
              }}
            />
          )}
        </GridItem>
        <GridItem area="actions">
          <Stack
            isInline
            justifyContent={{ base: "space-between", md: "flex-end" }}
          >
            <Stack isInline alignItems="center">
              <IconButton
                icon={
                  favoriteQuery ? (
                    <TwotoneStarIcon size={18} />
                  ) : (
                    <AiOutlineStar size={18} />
                  )
                }
                aria-label="filtering-labelled"
                onClick={() => {
                  const query = searchOptions.query.reduce(
                    (accum, q) => {
                      if (q.field !== PRIVATE_LABEL_QUERY_FIELD) {
                        accum.push(q);
                      }
                      return accum;
                    },
                    favoriteQuery
                      ? []
                      : [
                          {
                            field: PRIVATE_LABEL_QUERY_FIELD,
                            operator: "contains-all",
                            value: ["StarIcon-#edcb0e"]
                          } as SearchQueryItem
                        ]
                  );
                  const nextLocation = setSearchParams({
                    hasImage:
                      favoriteQuery === undefined ||
                      statusQuery !== undefined ||
                      query.some(q => queryFieldsHasImage.includes(q.field)),
                    query
                  });
                  history.push(nextLocation);
                }}
              />
              <Box maxWidth="120px">
                <StatusIcons
                  borderRadius="5px"
                  publicLabelleds={publicLabelleds}
                  onClick={({ name, color }) => {
                    const clickedIcon = `${name}-${color}`;
                    const removeClickedIcon = statusQueryValues.some(
                      icon => icon === clickedIcon
                    );
                    const selectedIcons = statusQueryValues.reduce(
                      (icons, icon) => {
                        if (icon.split("-")[0] !== name) {
                          icons.push(icon);
                        }
                        return icons;
                      },
                      removeClickedIcon ? [] : [clickedIcon]
                    );
                    const query = searchOptions.query.reduce(
                      (accum, q) => {
                        if (q.field !== PUBLIC_LABEL_QUERY_FIELD) {
                          accum.push(q);
                        }
                        return accum;
                      },
                      selectedIcons.length > 0
                        ? [
                            {
                              field: PUBLIC_LABEL_QUERY_FIELD,
                              operator: "contains-all",
                              value: selectedIcons
                            } as SearchQueryItem
                          ]
                        : []
                    );
                    const nextLocation = setSearchParams({
                      hasImage:
                        selectedIcons.length > 0 ||
                        favoriteQuery !== undefined ||
                        query.some(q => queryFieldsHasImage.includes(q.field)),
                      query
                    });
                    history.push(nextLocation);
                  }}
                  isActive={({ name, color }) =>
                    publicLabelleds.some(
                      item => item.name === name && item.color === color
                    )
                  }
                  isFilter
                  canAddIcon
                />
              </Box>
            </Stack>
            <Stack isInline alignItems="center">
              <ResponsiveButton
                onClick={handleSearch}
                leftIcon={<SearchIcon />}
              >
                {locale.searchButtonLabel}
              </ResponsiveButton>
              {isMemberFlg && (
                <>
                  {orgId !== "*" && (
                    <Box {...getRootProps()}>
                      <input {...getInputProps()} />
                      <ResponsiveButton leftIcon={<MdFileUpload size="20px" />}>
                        Add Images
                      </ResponsiveButton>
                    </Box>
                  )}
                  <ResponsiveButton
                    primary
                    onClick={() => history.push("/cases/new")}
                    leftIcon={<AddIcon fontSize="12px" />}
                  >
                    {locale.caseList.create}
                  </ResponsiveButton>
                </>
              )}
            </Stack>
          </Stack>
        </GridItem>
        {canCreatePrediction && (
          <GridItem area="prediction">
            <BatchProcessingButtons
              checkBox={checkBox}
              checkedImages={checkedImages}
              images={images}
              isPending={isPending}
              onCheckBoxAll={() => onCheckBoxAll(images.map(image => image.id))}
              startAIAnalysis={() =>
                checkAIAgreement(() => startAIAnalysis(orgId, checkedImages))
              }
              updateAllOrganModel={() =>
                checkAIAgreement(() =>
                  updateAllOrganModel(checkedImages, reload)
                )
              }
            />
          </GridItem>
        )}
      </Grid>
      {shouldShowSearchSummary && (
        <SearchSummary
          query={caseQuery}
          visibility={caseVisibility}
          onEdit={handleSearch}
          onReset={() => {
            const nextLocation = setSearchParams({
              hasImage: false,
              query: [],
              caseVisibility: "ALL"
            });
            history.push(nextLocation);
          }}
        />
      )}
      <CaseTable
        cases={cases}
        total={total}
        getQueryField={getQueryField}
        setSortOrder={setSortOrder}
        setQueryField={setQueryField}
        orderBy={order[0].field}
        orderDirection={order[0].direction}
        canCreatePrediction={canCreatePrediction}
        searchOptions={searchOptions}
        checkBox={checkBox}
        setCheckBox={setCheckBox}
        onToggleCheckBox={onToggleCheckBox}
        reload={reload}
      />
      <PaginatedListActionBar searchOptions={searchOptions} total={total} />
    </Container>
  );
};

const SearchBox = ({
  query,
  onChangeQuery
}: {
  query: CaseQuery;
  onChangeQuery: () => void;
}) => {
  const expression = query.getExpression("organizationId");
  const { currentOrgId, isSuper } = useAccount();
  const isSuperUser = isSuper();
  const orgId = expression?.value || currentOrgId;

  const onChangeOrg = value => {
    if (value === "*") {
      query.deleteExpression("organizationId");
    } else {
      query.setExpression("organizationId", { value, operator: "is" });
    }
    onChangeQuery();
  };

  return (
    <Box w={{ base: "auto", md: "300px" }}>
      <OrgPicker
        data-testid="OrgPicker"
        onChange={onChangeOrg}
        defaultOrgId={orgId}
        showAllOption={isSuperUser}
      />
    </Box>
  );
};

export const SearchSummary = ({
  query,
  visibility,
  onReset,
  onEdit
}: {
  query: CaseQuery;
  visibility?: SearchOptions["caseVisibility"];
  onEdit: () => void;
  onReset: () => void;
}) => {
  return (
    <Flex
      alignItems="center"
      mb={4}
      px={4}
      py={2}
      bg="white"
      borderTopWidth="1px"
      borderBottomWidth="1px"
      borderRadius="4px"
    >
      <Box flexGrow={1}>
        <QuerySummary query={query} />
        {visibility && <VisibilitySummary visibility={visibility} />}
      </Box>
      <Flex>
        <SearchActions onReset={onReset} onEdit={onEdit} />
      </Flex>
    </Flex>
  );
};

const QuerySummary = ({ query }: { query: CaseQuery }) => {
  const locale = useLocale();
  if (query.isEmpty()) {
    return null;
  }
  const models = modelData as PredictionModel[];
  const fields = query
    .describe()
    .filter(({ field }) => field !== "organization-id")
    .map(item => ({
      ...item,
      field: item.field === "display-name" ? "name" : item.field,
      value:
        item.field === "model-name"
          ? models.find(
              model =>
                typeof item.value === "string" &&
                model.modelNameEnum === item.value.replace(/["]/g, "")
            )?.displayName
          : item.value
    }));
  if (fields.length === 0) return null;

  return (
    <Flex alignItems="center" flexWrap="wrap">
      <Box mr={1}>{locale.caseSearch.results}</Box>
      {fields.map(({ field, operator, value }, index) => (
        <Flex key={index} alignItems="center">
          {index > 0 && <Box pr={2}>and</Box>}
          {(operator === "is" || operator === "is-like") && (
            <Box pr={2}>{`${field}\u2009=\u2009${value}`}</Box>
          )}
          {operator === "is-not" && (
            <strong>{`${field}\u2009≠\u2009${value}`}</strong>
          )}
          {operator === "is-greater-than" && (
            <strong>{`${field}\u2009>\u2009${value}`}</strong>
          )}
          {operator === "is-empty" && <strong>{`${field} is empty`}</strong>}
          {operator === "is-not-empty" && (
            <strong>{`${field} is not empty`}</strong>
          )}
          {operator === "contains-any" && Array.isArray(value) && (
            <Flex alignItems="center">
              <strong>{`${field} contains ${
                value.length === 1 ? "" : "any of "
              }`}</strong>
              <TagList tags={value} />
            </Flex>
          )}
          {operator === "contains-all" && Array.isArray(value) && (
            <>
              <strong>{`${field} contains ${
                value.length === 1 ? "" : "all of "
              }`}</strong>
              <TagList tags={value} />
            </>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

const VisibilitySummary = ({
  visibility
}: {
  visibility: SearchOptions["caseVisibility"];
}) => {
  const locale = useLocale();
  if (visibility === "PUBLIC") {
    return <Box>{locale.caseSearch.visibility.options.PUBLIC}</Box>;
  }
  if (visibility === "PRIVATE") {
    return <Box>{locale.caseSearch.visibility.options.PRIVATE}</Box>;
  }
  return null;
};

const SearchActions = ({
  onReset,
  onEdit
}: {
  onEdit: () => void;
  onReset: () => void;
}) => {
  const locale = useLocale();
  return (
    <>
      <IconButton
        icon={<EditIcon />}
        isRound
        aria-label={locale.caseSearch.editButtonLabel}
        onClick={onEdit}
        ml={4}
      />
      <IconButton
        icon={<CloseIcon />}
        isRound
        aria-label={locale.clearButtonLabel}
        onClick={onReset}
        ml={4}
      />
    </>
  );
};

const TagList = ({ tags }: { tags: string[] }) => {
  return (
    <Stack isInline mx={2}>
      {tags.map(tag => (
        <Tag key={tag} maxW={200}>
          <TagLabel>{tag}</TagLabel>
        </Tag>
      ))}
    </Stack>
  );
};

// A responsive version of the `Button` component to hide the text on small screens
// TODO: export from `core` components and handle `rightIcon`... if it's the right abstraction.
const ResponsiveButton = (props: React.ComponentProps<typeof Button>) => {
  const { children, leftIcon, ...rest } = props;
  return (
    <>
      <Button
        {...rest}
        display={{ base: "inline-flex", xl: "none" }}
        w="35px"
        leftIcon={leftIcon}
        paddingLeft="19px"
      />
      <Button
        {...rest}
        display={{ base: "none", xl: "inline-flex" }}
        leftIcon={leftIcon}
      >
        {children}
      </Button>
    </>
  );
};
